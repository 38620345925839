import React from "react"
import loadable from '@loadable/component'

import SEO from '../components/SEO'

const Logo = loadable(() => import("../components/Logo"));
const Content = loadable(() => import('../components/Content'));
const Section = loadable(() => import('../components/Section'));
const InnerSection = loadable(() => import('../components/InnerSection'));

export default function Home() {
  return (
    <main>
      <SEO/>
      <Logo/>
      <Content>
        <Section title='TERMO DE CESSÃO' id='how'>
          <InnerSection style={{margin: '70px 25px 100px 25px'}}>
            <p>
              Através do presente instrumento de TERMO DE CESSÃO, o USUÁRIO/CESSIONÁRIO, declara ter lido, compreendido, e que concorda com os seguintes TERMOS:
              <br />
              <br />
              Estar recebendo, neste ato, uma unidade da COLEIRA INTELIGENTE URDOG, a qual, durante o período poderá, optativamente, devolve-la ou mantê-la em sua posse, porém, caso opte por mantê-la, dispõe-se a pagar valor inicial em garantia equivalente a R$ 477,00, e a iniciar, a partir do primeiro mês, fazer o pagamento do valor equivalente à mensalidade que ora está estabelecida em R$ 29,99. <br/>
              A mensalidade poderá ser majorada, e sua aplicação deverá ser apresentada ao USUÁRIO/CESSIONÁRIO, o qual poderá decidir por não manter a utilização do dispositivo, devolvendo-o. <br/>
              Com a devolução do dispositivo, o USUÁRIO/CESSIONÁRIO tornar-se-á isento de qualquer valor que se caracterize como indenização material ou moral, em seu favor ou desfavor, a que título for. <br/>
              Caso a devolução seja feita durante os primeiros 12 meses, e em bom funcionamento, a Urdog se compromete com a devolução do valor pago inicial para obter acesso a coleira inteligente Urdog, proporcional ao numero de meses utilizados durante os 12 meses iniciais, devoluções após esse período serão isentas de quaisquer devoluções por parte da Urdog. <br/>
              <br/>
            </p>
            <br/>
            <p>
              Declara o USUÁRIO/CESSIONÁRIO conhecer e concordar que a COLEIRA INTELIGENTE URDOG é um dispositivo, o qual o USUÁRIO/CESSIONÁRIO, por sua livre e total responsabilidade, poderá instalá-lo, por si ou por profissional veterinário, ao redor do pescoço de seu “PET”, e, que, com a instalação do App URDOG através e no dispositivo móvel (celular) de sua propriedade, acessando-o, e com a inserção das informações cadastrais ali requisitados, terá acesso a alguns dos dados, capturados do seu “pet”. <br/>
              Por esses “insights”, gerados por inteligência artificial, o USUÁRIO/CESSIONÁRIO receberá “alertas” sobre parte desses dados, os quais indicarão facetas do comportamento do seu PET.
              <br />
              <br />
              <ul>
                <li>O USUÁRIO/CESSIONÁRIO declara conhecer e concordar que não terá acesso a todos os dados capturados na sua forma original. <br/></li>
                <li>O USUÁRIO/CESSIONÁRIO terá acesso a informações capturadas e processadas, que indicam a atividade, posição e temperatura do seu “PET”.<br/></li>
                <li>O USUÁRIO/CESSIONÁRIO terá a opção de escolher ativar o modo “perdi meu cachorro” caso deseje conhecer a localização aproximada do seu “pet”.<br/></li>
                <li>O USUÁRIO/CESSIONÁRIO, à medida que novas versões da coleira inteligente sejam lançadas e disponibilizadas, concorda e autoriza que possam ser alterados a quantidade de dados e informações, as quais poderão ser apresentadas de forma diferente daquelas já disponíveis na primeira versão.</li>
                <li>O USUÁRIO/CESSIONÁRIO declara conhecer e concordar, por isso mesmo, aceitar através de sua assinatura voluntária, com o TERMO DE USO que passa a ser parte integrante e indissociável deste TERMO DE CESSÃO.</li>
              </ul>
            </p>
            <br/>
            <p>
              Declara o USUÁRIO/CESSSIONÁRIO, ainda, conhecer e concordar que:        
              <br />
              <ul>
                <li>Na hipótese de que a URDOG deixe, por iniciativa própria, de exercer suas atividades, ela ficará plenamente isenta de manter o dispositivo em funcionamento, bem como a disponibilização de dados informativos de seu PET, assim como o USUÁRIO/CESSIONÁRIO ficará desobrigado a qualquer pagamento de mensalidade.</li>
                <li>Na hipótese de que a URDOG venha a requerer falência ou ter a sua falência requerida, ela ficará plenamente isenta de manter o dispositivo em funcionamento, bem como a disponibilização de dados informativos de seu PET, assim como o USUÁRIO/CESSIONÁRIO ficará desobrigado a qualquer pagamento de mensalidade.</li>
                <li>Na hipótese de que a URDOG venha a ser vendida ou sucedida, os termos da presente CESSÃO poderão, a critério exclusivo do novo proprietário ou sucessor, a ter os seus termos revistos, podendo o USUÁRIO/CESSIONÁRIO optar por dar termo à presente CESSÃO ou ainda, por mantê-la, conforme seu próprio interesse, e, desde que os possíveis novos termos da CESSÃO estejam de acordo com seus interesses.</li>
              </ul>
            </p>
            <br/>
            <p>
              O USUÁRIO/CESSIONÁRIO dispõe-se, não obrigatoriamente, e, de conformidade com o senso de cooperação, a responder eventuais contatos da URDOG na forma de questionários, contatos telefônicos, e-mails, e, eventuais encontros pessoais ou vituais, que poderão ou não incluir exames fisiológicos do PET, além da verificação física da COLEIRA INTELIGENTE URDOG.
              <br/>
              E por declararem total acordo em relação aos TERMOS DE CESSÃO, as partes firmam o presente instrumento.
              <br/>
            </p>
            <br/>
          </InnerSection>
        </Section>
      </Content>
    </main>
  )
}